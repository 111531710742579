import styled from "styled-components";
import fairnessIcon from '../../assets/fairnessIcon.png';
import communityIcon from '../../assets/communityIcon.png';
import developmentIcon from '../../assets/developmentIcon.png';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals, faPeopleGroup, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 70vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: white;
    @media screen and (max-width: 900px) {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    }
    `
const InnerContainer = styled.div`
    width: 70%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    @media screen and (max-width: 900px) {
    margin-top: 25px;
    width: 90%;
    max-width: 90%;
    }
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-bottom: 25px;
  }
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `
const RadialGradientA = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: -50px;
    left: 50px;
    zIndex: 19;
    `
const SuperTitleText = styled.div`
    color: #FF4DCF;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 22px;
    `
const TitleText = styled.div`
    color: #00011c;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 62px;
    span {
    color: #45D9F4; }
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 500;
    font-size: 16px;
    `
const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 120%;
    height: 200px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    @media screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;
    margin-bottom: 0;
    }
    `
const InfoComponent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 28%;
    border: 5px solid #00011c; 
    border-radius: 40px;
    align-items: center;
    padding-top: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 4px 0px 6px rgba(0, 0, 0, 0.05), -4px 0px 6px rgba(0, 0, 0, 0.05);
    `
const InfoComponentRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
    flex-direction: column;}
    `
const InfoComponentColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 900px) {
    justify-content: center;
    text-align: center;}
    `
const ImageIcon = styled.img`
    width: 35px;
    height: 35px;
    `
const InviteTitleText = styled.div`
    color: #00011c;
    font-family: DM Sans;
    font-weight: 700;
    font-size: 22px;
    margin-top: 20px;
    `
const InviteText = styled.div`
    color: #111111;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 14px;
    text-shadow: 1px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    `
const IconContainer = styled.div`
    height: 60px;
    width: 100px;
    border-radius: 30px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: purple;
    align-items: center;
    justify-content: center;
    display: flex;
    `
const IconContainerA = styled.div`
    height: 60px;
    width: 100px;
    border-radius: 30px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: green;
    align-items: center;
    justify-content: center;
    display: flex;
    `
const IconContainerB = styled.div`
    height: 60px;
    width: 100px;
    border-radius: 30px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: blue;
    align-items: center;
    justify-content: center;
    display: flex;
    `



const ThirdContainer = ({ RadialGradientB }) => {
    return (
        <Container>
            
            <InnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <SuperTitleText>VALUES</SuperTitleText>
                        <TitleText>Our Promises</TitleText>
                    </TopMasterRowColumn>
                </TopMasterRow>
                <InfoContainer>
                    <InfoComponent>
                            <IconContainer>
                                <FontAwesomeIcon icon={faEquals} color="white" size="xl" />
                            </IconContainer>
                            <InviteTitleText>Fairness</InviteTitleText>
                        
                        <InfoComponentColumn>
                            <InviteText>We have process' in place to ensure that the distribution of
                                Hydrix will be based on Mining activity and engagement only.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>
                    <InfoComponent>
                        
                            <IconContainerA>
                            <FontAwesomeIcon icon={faPeopleGroup} color="white" size="xl" />
                            </IconContainerA>
                            <InviteTitleText>Community</InviteTitleText>
                        
                        <InfoComponentColumn>
                            <InviteText>We place our community at the centre of everything we do at Hydrix.
                                We work on your behalf with gratitude.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>
                    <InfoComponent>
                        
                            <IconContainerB>
                            <FontAwesomeIcon icon={faProjectDiagram} color="white" size="xl" />
                            </IconContainerB>
                            <InviteTitleText>Development</InviteTitleText>
                        
                        <InfoComponentColumn>
                            <InviteText>From inception right through to token launch, we spend each day developing
                                the product to make it better.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>
                </InfoContainer>
                
            </InnerContainer>
        </Container>

    )
};

export default ThirdContainer;
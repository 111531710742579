import styled from "styled-components";
import HomeFAQList from "./HomeFAQList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import FAQ from '../../assets/FAQ.png';

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: white;
    `
const RadialGradient = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 700px;
    height: 700px;
    position: absolute;
    top: 10%;
    left: -250px;
    zIndex: 20;
    overflow: hidden;
    `
const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    overflow: hidden;
    right: 300px;
    zIndex: 19;
    `
const InnerContainer = styled.div`
    width: 80%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    background-color: #00011c;
    border-radius: 4px;
    border-top-right-radius: 200px;
    border-bottom-left-radius: 200px;
    margin-bottom: 50px;
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    `
    const TitleText = styled.div`
    color: #00011c;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 62px;
    span {
    color: #45D9F4; }
    `
const SuperTitleText = styled.div`
    color: #FF4DCF;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 22px;
    `
const SubTitleText = styled.div`
    color: #c1cade;
    font-family: DM Sans;
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    `
const Button = styled.button`
    display: flex;
    flex-direction: row;
    width: 200px;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 2px solid #FF4DCF;
    background-color: #FF4DCF;
    padding: 0px 15px 0px 15px;
    `
const ButtonText = styled.div`
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 600;
    color: black;
    `
const Image = styled.img`
    width: 300px;
    height: auto;
    position: absolute;
    top: -20%;
    left: -10%;
    `
const FifthContainer = () => {
    return (
        <Container>
            <TopMasterRow>
                        <SuperTitleText>FAQ</SuperTitleText>
                        <TitleText>Any Questions?</TitleText>
                    <a href="https://www.t.me/hydrixcommunityportal" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <Button>
                            <ButtonText>Ask a Question</ButtonText>
                            <FontAwesomeIcon icon={faArrowRight} size="1x" color="#001226" />
                        </Button>
                    </a>
                </TopMasterRow>
            <InnerContainer>
                
                <HomeFAQList />
                <Image src={FAQ} />
            </InnerContainer>
        </Container>

    )
};

export default FifthContainer;
import styled from "styled-components";
import FairnessHomePage from '../../assets/fairnessHomePage.png';
import Opinion from '../../assets/opinion.png';
import Efficiency from '../../assets/efficiency.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals, faPeopleGroup, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";


const Container = styled.div`
    width: 100%;
    height: 130vh; 
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    `
const Wrapper = styled.div`
    width: 90%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    `
const TitleText = styled.div`
    color: #00011c;
    font-size: 32px;
    font-family: Anek Devanagari;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: left;
    line-height: .925;
    `
const HeaderRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    `
const TitleContainer = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    `
const Image = styled.img`
    width: 40px;
    height: 30px;
    margin-right: 5px;
    `
const ImageA = styled.img`
    width: 30px;
    height: 30px;
    
    `
const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #00011c;
    font-family: DM Sans;
    text-align: left;
    margin-bottom: 20px;
   
    `
const ButtonA = styled.button`
    border-radius: 4px;
    border: 2px solid #00FAD9;
    height: 60px;
    width: 80%;
    margin-top: 30px;
    background-color: #00FAD9; 
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: #00011c;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const ScreenShotImg = styled.img`
    width: 70%;
    height: 300px;
    `
const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    `
const InfoComponent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100;
    background-color: #00011c;  
    border-radius: 4px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;

    
    `
const InfoComponentRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
    flex-direction: column;}
    `
const InfoComponentColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 900px) {
    justify-content: center;
    text-align: center;}
    `
const IconContainer = styled.div`
    height: 60px;
    width: 100px;
    border-radius: 30px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: purple;
    align-items: center;
    justify-content: center;
    display: flex;
    `
const IconContainerA = styled.div`
    height: 60px;
    width: 100px;
    border-radius: 30px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: green;
    align-items: center;
    justify-content: center;
    display: flex;
    `
const IconContainerB = styled.div`
    height: 60px;
    width: 100px;
    border-radius: 30px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: blue;
    align-items: center;
    justify-content: center;
    display: flex;
    `
const InviteTitleText = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 20px;
    margin-top: 20px;
    `
const InviteText = styled.div`
    color: #c1cade;
    font-family: DM Sans;
    font-weight: 600;
    font-size: 14px;
    `
const ImageIcon = styled.img`
    height: 40px;
    width: 40px;
    `
const HomePageFourth = () => {
    return (
        <Container>
            <Wrapper>
                <TitleText>Why Hydrix?</TitleText>
                <Subtitle>What you can expect from us</Subtitle>
                <InfoComponent>
                    
                        <IconContainer>
                            <FontAwesomeIcon icon={faEquals} color="white" size="xl" />
                        </IconContainer>
                            <InviteTitleText>Fairness</InviteTitleText>
                        
                        <InfoComponentColumn>
                            <InviteText>We have process' in place to ensure that the distribution of
                                Hydrix will be based on Mining activity and engagement only.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>
                    <InfoComponent>
                    
                        <IconContainerA>
                            <FontAwesomeIcon icon={faPeopleGroup} color="white" size="xl" />
                        </IconContainerA>
                            <InviteTitleText>Development</InviteTitleText>
                        
                        <InfoComponentColumn>
                            <InviteText>From inception right through to token launch,
                                we spend each day developing the product to make it better.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>
                    <InfoComponent>
                    
                        <IconContainerB>
                            <FontAwesomeIcon icon={faProjectDiagram} color="white" size="xl" />
                        </IconContainerB>
                            <InviteTitleText>Community</InviteTitleText>
                        
                        <InfoComponentColumn>
                            <InviteText>We place our community at the centre of everything
                                we do at Hydrix. We work on your behalf with gratitude.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>

              
            </Wrapper>
            
        </Container>
    )
};

export default HomePageFourth;

import styled from 'styled-components';
import { useState } from 'react';
import Headerhand from '../assets/Headerhand.png';
import { Icon } from '@iconify/react/dist/iconify.js';
import CircleImage from '../assets/CircleImage.png'
import ScreenShot2 from '../assets/ScreenShot2.png';
import Header from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import AndroidImg from '../assets/android.png';
import SecondContainer from '../components/Home/SecondContainer';
import ThirdContainer from '../components/Home/ThirdContainer';
import FourthContainer from '../components/Home/FourthContainer';
import NewFooter from '../components/NewFooter';
import googlePlay from '../assets/googlePlay.png';
import appStore from '../assets/AppStore.png';
import HomeFAQList from '../components/Home/HomeFAQList';
import FifthContainer from '../components/Home/FifthContainer';
import SixthContainer from '../components/Home/SixthContainer';
import OpacityLogo from '../assets/OpacityLogo.png';
import EmailModal from '../components/EmailModal';
import TopBanner from '../components/Home/TopBanner';
import FrontBackground from '../assets/FrontBackground.jpg';



const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    background-color: #00011c;
    `
const TopOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(20, 20, 20, 1), rgba(20, 20, 20, 0.7));
    `
const BottomOverlay = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(20, 20, 20, 1), rgba(20, 20, 20, 1), rgba(20, 20, 20, 1), rgba(20, 20, 20, 0.7));
    `
const InfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 50%;
    height: 70vh;
    z-index: 99;
    text-align: left;
    `
const InfoDivWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 99;
    margin-left: 150px;
    margin-top: 50px;
    text-align: left;
    `
const MasterDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 70vh;
    `
const InfoDivA = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 400px;
    height: 400px;
    background: url(${FrontBackground});
    background-position: center;
    background-size: cover;
    z-index: 99;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 100px;
    position: relative;
    `
const BackBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 400px;
    height: 400px;
    background-color: #45D9F4;  
    z-index: 98;
    border-radius: 4px;
    border-bottom-left-radius: 100px;
    position: absolute;
    bottom: 7%;
    right: 15%;
    `
const TitleText = styled.div`
    color: white;
    font-size: 4rem;
    font-family: Anek Devanagari;
    font-weight: 1000;
    margin-bottom: 20px;
    line-height: .925;
    width: 70%;
    text-transform: capitalize;
    span {
    color: #45D9F4; }
    `
export const RadialGradient = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    left: -250px;
    zIndex: 19;
  `
export const RadialGradientA = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.15) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 200px;
    left: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
  `
export const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 50px;
    zIndex: 19;
  `
const RadialGradientC = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.3) 30%, #1A0135 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: -150px;
    right: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
`
const SubTitle = styled.div`
    color: #c1cade;
    font-size: 22px;
    font-weight: 500;
    font-family: DM Sans;
    margin-bottom: 40px;
    width: 75%;
    
    `
const SubTitleA = styled.div`
    color: white;
    font-size: 20px;
    font-weight: 400;
    font-family: Work Sans;
    text-align: center;
    margin-bottom: 5px;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 90vw;}
    `
const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: #FF4DCF;
    padding: 15px;
    margin-right: 10px;
    border: 2px solid #FF4DCF;
    width: 200px;
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: black;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 18px
    `
const IconContainer = styled.div`
    position: absolute;
    top: 30%;
    `
const AndroidImage = styled.img`
    z-index: 999;
    margin-top: 25px;
    @media screen and (max-width: 900px) {
    display: none;
    `
const MasterContainer = styled.div`
    background-color: #00011c;
    `
const Image = styled.img`
    width: 175px;
    height: 55px;
    margin-right: 15px;
    cursor: pointer;
    `
const ImageA = styled.img`
    width: 400px;
    height: auto;
    margin-bottom: 200px;
    margin-right: 200px;
    `

    

const New = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const openModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);
    

    return (
    <MasterContainer>
         <Header />
         <TopBanner />
            <Container>
            <InfoDiv>
                <InfoDivWrapper>
                <TitleText>Mine <span>Hydrix</span> on Your Smartphone</TitleText>
                <SubTitle>Available on both Android and iOS. Simply download the app and tap the button
                    to keep your mining active.
                </SubTitle>
                <ButtonRow>
                <a href="https://play.google.com/store/apps/details?id=com.HydrixApp" target="_blank" rel="noopener noreferrer">
                    <Image src={googlePlay} />
                </a>
                    <Image src={appStore} />
                    
                </ButtonRow>
                </InfoDivWrapper>
            </InfoDiv>
            <MasterDiv>
                <BackBox />
            <InfoDivA>
                
            </InfoDivA>
            </MasterDiv>
            <EmailModal isVisible={isModalVisible} onClose={closeModal} />
           
            
            
        </Container>
        <SecondContainer RadialGradient={RadialGradient} />
        <ThirdContainer RadialGradientB={RadialGradientB} RadialGradient={RadialGradient} />
        <FourthContainer RadialGradientB={RadialGradientB} RadialGradientA={RadialGradientA} />
        <SixthContainer RadialGradient={RadialGradient} RadialGradientB={RadialGradientB} />
        <FifthContainer />

        <NewFooter />
    </MasterContainer>


        
            
        

    )
}

export default New;
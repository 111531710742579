import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import DiscordTrans from '../../assets/discordTrans.png';
import TelegramTrans from '../../assets/telegramTrans.png';
import FacebookTrans from '../../assets/facebookTrans.png';
import RedditTrans from '../../assets/redditTrans.png';
import TikTokTrans from '../../assets/tiktokTrans.png';
import TwitterTrans from '../../assets/twitterTrans.png';
import MediumTrans from '../../assets/mediumTrans.png';
import Marquee from 'react-fast-marquee';
import DiscordGray from '../../assets/discordGray.png';
import RedditGray from '../../assets/redditGray.png';
import FacebookGray from '../../assets/facebookGray.png';
import TelegramGray from '../../assets/telegramGray.png';
import TwitterGray from '../../assets/twitterGray.png';
import MediumGray from '../../assets/mediumGray.png';
import TikTokGray from '../../assets/tiktokGray.png';
import { isMobile } from 'react-device-detect';

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 55vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
    `
const InnerContainer = styled.div`
    width: 80%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    @media screen and (max-width: 900px) {
    display: none;}
    `
const TitleText = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 62px;
    span {
    color: #45D9F4; }
    `
const SuperTitleText = styled.div`
    color: #c1cade;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 22px;
    `
const SubTitleText = styled.div`
    color: #c1cade;
    font-family: DM Sans;
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    `
const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 300px;
    zIndex: 19;
     `
const CommunityComponentText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 18px;
    padding-right: 20px;
    @media screen and (max-width: 900px) {
    font-size: 12px;}
    `
const IconContainer = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    `
const CommunityContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 300px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    @media screen and (max-width: 900px) {
    justify-content: center;
  }
    `
const CommunityComponent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 48%;
    background-color: #00011c;
    border-radius: 4px;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
    @media screen and (max-width: 900px) {
    display: none;

    `
const CommunityComponentRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    }
    `
const CommunityComponentA = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 48%;
    border-radius: 4px;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (max-width: 900px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    }

    `
const CommunityComponentImageA = styled.img`
    width: 24px;
    height: 24px;
    padding-right: 20px;
    `
const CommunityContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 15px;
    width: 80%;
    `
const CommunityTitleText = styled.div`
    color: #999999;
    font-family: Sans-serif;
    font-weight: 500;
    font-size: 14px;
    
    `
const IconRow = styled.div`
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;
    border-bottom: 1px solid #222222;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(5, 1fr); 
    gap: 20px; 
    `
const CommunityOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.3));
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: #FF4DCF;
    border: 2px solid #FF4DCF;
    padding: 15px;
    width: 200px;
    margin-top: 20px;
    `
const ButtonText = styled.div`
    color: black;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const MarqueeComponent = styled.div`
    width: 75px;
    height: 75px;
    margin: 25px;
    `
const ImageWrapper = styled.img`
    @media screen and (max-width: 900px) {
    width: 30px;
    height: 30px;}
    `


const SecondContainer = ({ RadialGradient }) => {
    return (
        <Container>
            <InnerContainer>
                <TopMasterRow>
                    <SuperTitleText>ABOUT</SuperTitleText>
                    <TitleText>What is <span>Hydrix?</span></TitleText>
                    <SubTitleText>Hydrix makes crypto mining effortless and rewarding, 
                        bringing cryptocurrency within everyone’s reach. Whether you're
                        new to crypto or an expert, Hydrix lets you earn rewards seamlessly 
                        from your smartphone—no expensive hardware or complicated setups required.
                        With a simple, intuitive interface, you can start mining with a tap. Our
                        tiered reward system offers bonuses of up to 100%, and you can boost 
                        your earnings further by building a team and growing the Hydrix network.
                        Hydrix prioritizes security, transparency, and sustainability. Your data 
                        is safe, your progress is clear, and you can mine without the 
                        energy-intensive costs of traditional methods.
                        Join a community shaping the future of digital assets. 
                        Download Hydrix today and start building your crypto portfolio!
                    </SubTitleText>                  
                </TopMasterRow>
                
                
            </InnerContainer>
                
        </Container>

    )
};

export default SecondContainer;
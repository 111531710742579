import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    background-color: #45D9F4;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    `
const SubTitle = styled.div`
    color: rgba(26, 10, 43, 1);
    font-size: 22px;
    font-weight: 600;
    font-family: DM Sans;
    `

const TopBanner = () => {
    return (
        <Container>
            <SubTitle>Sign Up and Receive 50 Hydrix Free!</SubTitle>
        </Container>
    )
};

export default TopBanner;
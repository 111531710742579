import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import NewFooter from '../components/NewFooter';
import Novice from '../assets/novice.png';
import Middle from '../assets/middle.png';
import End from '../assets/end.png';
import Arrow from '../assets/arrows.png';
import One from '../assets/one.png';
import Two from '../assets/number-2.png';
import Three from '../assets/number-3.png';
import { isMobile } from 'react-device-detect';
import AffiliateImage from '../assets/AffiliateYes.jpg';
import GetInvolved from '../assets/GetInvolved.png';
import GetInvolvedA from '../assets/GetInvolvedA.png';
import AffiliateRandom from '../assets/AffiliateRandom.png';




const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
    @media screen and (max-width: 900px) {
        height: auto;
    }
    `
const TopWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    background-color: #00011c;
    `
const InnerContainer = styled.div`
    width: 80%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
    `
const ContainerWrapper = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: auto;
    background-color: white;
    `
const ImageIcon = styled.img`
    width: 45px;
    height: 45px;
    `
const IconContainer = styled.div`
    height: 75px;
    width: 75px;
    border-radius: 37.5px;
    background-color: rgba(0, 250, 217, 0.3);
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
    @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 20px;}
    `
const InnerInnerContainer = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 500px;
    
    `
const InnerInnerInnerContainer = styled.div`
    width: 120%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 700px;
    background-color: white;
    border-radius: 4px;
    border-top-right-radius: 200px;
    border-bottom-left-radius: 200px;
    position: relative;
    `
const RandomImage = styled.img`
    position: absolute;
    width: 300px;
    height: auto;
    left: -2%;
    top: -25%;
    `
const Casual = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
    border-radius: 40px;
    height: auto;
    padding-bottom: 20px;
    @media screen and (max-width: 900px) {
    width: 90vw;
    padding-bottom: 20px;
    height: auto;}
    `
const HowWhat = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #00011c; 
    border-radius: 40px;
    align-items: center;
    padding-top: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 4px 0px 6px rgba(0, 0, 0, 0.05), -4px 0px 6px rgba(0, 0, 0, 0.05);
    `
const TitleText = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 40px;
    background-color: purple;
    width: 60%;
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    `
const ATitleText = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 40px;
    background-color: blue;
    width: 60%;
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    `
const BTitleText = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 40px;
    background-color: green;
    width: 60%;
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    `
const SubTitleText = styled.div`
    color: #c1cade;
    font-size: 22px;
    font-weight: 500;
    font-family: DM Sans;
    margin-bottom: 40px;
    
    
    `
const SubTitleTextA = styled.div`
    color: #c1cade;
    font-family: DM Sans;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    width:80%;
    
    `
const SubTitleTextB = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    width:90%;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 80%;
    text-align: center;}
    `
const HowWhatColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    align-items: center;
    justify-content: center;
   
    `
const Button = styled.button`
    padding: 10px 20px;
    background-color: pink; //#FF4DCF;
    border: 2px solid #FF4DCF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;

    &:hover {
        background-color: #FF4DCF;
    }
    `
const RadialGradient = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    left: -250px;
    zIndex: 19;
    `
const RadialGradientE = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 350px;
    height: 350px;
    position: absolute;
    top: 1000px;
    left: -50px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;}
    `
const RadialGradientA = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.2) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 200px;
    left: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
    `
const RadialGradientF = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.2) 30%, #00011c 70%);
    width: 600px;
    height: 600px;
    position: absolute;
    top: 800px;
    right: 0;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
    `
const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 50px;
    zIndex: 19;
    `
const RadialGradientC = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.3) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: -150px;
    right: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
    `
const ButtonText = styled.div`
    color: black;
    font-family: DM Sans;
    font-weight: 600;
    font-size: 16px
    `
const Form = styled.form`
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    `
const Message = styled.div`
  font-family: DM Sans;
  font-weight: 500;
  font-size: 10px;
  color: #999999;
  margin-top: 10px;
  margin-bottom: 40px;
    `
const Image = styled.img`
    width: 80%;
    height: 225px;
    margin-top: 35px;
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 75px;
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justtify-content: center;
    `
const CasualColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 25%;
   
    `
const InfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 50%;
    height: 70vh;
    z-index: 99;
    text-align: left;
    `
const InfoDivWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 99;
    margin-left: 150px;
    margin-top: 50px;
    text-align: left;
    `
const TopTitleText = styled.div`
    color: white;
    font-size: 4rem;
    font-family: Anek Devanagari;
    font-weight: 1000;
    margin-bottom: 20px;
    line-height: .925;
    width: 70%;
    text-transform: capitalize;
    span {
    color: #45D9F4; }
    `
const TopSubTitle = styled.div`
    color: #c1cade;
    font-size: 22px;
    font-weight: 500;
    font-family: DM Sans;
    margin-bottom: 40px;
    width: 75%;
    
    `
const TopSubTitleA = styled.div`
    color: black;
    font-size: 22px;
    font-weight: 500;
    font-family: DM Sans;
    width: 75%;
    
    `
const MasterDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 70vh;
    position: relative;
    `
const InfoDivA = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 400px;
    height: 400px;
    background: url(${AffiliateImage});
    background-position: center;
    background-size: cover;
    z-index: 99;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 100px;
    position: relative;
    `
const BackBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 400px;
    height: 400px;
    background-color: #45D9F4;  
    z-index: 98;
    border-radius: 4px;
    border-bottom-left-radius: 100px;
    position: absolute;
    bottom: 5%;
    right: 30%;
    `
const InviteTitleText = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 22px;
    margin-top: 20px;
    background-color: #45D9F4;
    width: 80%;
    border-radius: 4px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 15px 0px 15px 0px;
    `
const InviteTitleTextA = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 22px;
    margin-top: 20px;
    background-color: green;
    width: 80%;
    border-radius: 4px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 15px 0px 15px 0px;
    `
const InviteTitleTextB = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 22px;
    margin-top: 20px;
    background-color: purple;
    width: 80%;
    border-radius: 4px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 15px 0px 15px 0px;
    `
const InviteText = styled.div`
    color: #c1cade;
    font-family: DM Sans;
    font-weight: 600;
    font-size: 14px;
    text-shadow: 1px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    width: 80%;
    `
const NewTitleText = styled.div`
    color: white;
    font-size: 4rem;
    font-family: Anek Devanagari;
    font-weight: 1000;
    margin-bottom: 20px;
    line-height: .925;
    text-transform: capitalize;
    span {
    color: #45D9F4; }
    `
const TextWrapper = styled.div`
    height: 150px;
    display: flex;
    justify-content: center;
    `
const JoinBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 40px;
    position: absolute;
    background-color: pink;
    z-index: 999999;
    right: 70%;`

const Affiliate = () => {
    
    return (
        <Container>
            <Header />
                <TopWrapper>
                    <InfoDiv>
                        <InfoDivWrapper>
                            <TopTitleText>Become an <span>Affiliate</span></TopTitleText>
                            <TopSubTitle>Earn rewards for your contribution to our success!</TopSubTitle>
                        </InfoDivWrapper>
                    </InfoDiv>
                    <MasterDiv>
                        <BackBox />
                        <JoinBox>
                            <TopSubTitleA>
                                Join the Community
                            </TopSubTitleA>
                        </JoinBox>
                    <InfoDivA>
                
                    </InfoDivA>
            </MasterDiv>

                </TopWrapper>
            <ContainerWrapper>
            <InnerContainer>
                <InnerInnerContainer>
                    <HowWhatColumn>
                        <HowWhat>
                            <InviteTitleText>Get Involved</InviteTitleText>
                            <InviteText>Start by actively engaging with the Hydrix community and sharing
                                the app on your social media, blogs, or other platforms. It's not just
                                about the number of people you reach—it's about the quality of your efforts.
                                Share informative, thoughtful content about Hydrix, encouraging others to
                                explore the app and its benefits.
                            </InviteText>
                            <Image src={GetInvolved} />
                        </HowWhat>
                        
                    </HowWhatColumn>
                    
                    <HowWhatColumn>
                        <HowWhat>
                            <InviteTitleTextA>Effort Over Impact</InviteTitleTextA>
                            <InviteText>While having a broad reach is great, it's the consistency and quality
                                of your efforts that matter most. We’re looking for users who genuinely engage
                                with the platform and put in the effort to promote it in creative, authentic ways.
                                Your efforts don’t have to go viral to make a difference! We love those who create!
                            </InviteText>
                            <Image src={GetInvolvedA} />
                        </HowWhat>
                        
                    </HowWhatColumn>
                    
                    <HowWhatColumn>
                        <HowWhat>
                            <InviteTitleTextB>Ensure Visibility</InviteTitleTextB>
                            <InviteText>Make sure your promotions and contributions can be verified by our
                                communications team. Whether it's social media posts, blog articles, or community
                                discussions, we need to confirm your activities before accepting you as an
                                affiliate. Keep a record of your efforts and make them easily accessible for
                                verification.
                            </InviteText>
                            <Image src={GetInvolved} />
                        </HowWhat>
                        
                    </HowWhatColumn>
                    
                </InnerInnerContainer>
            </InnerContainer>
            </ContainerWrapper>
            <InnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <NewTitleText>Choose <span>Honestly</span></NewTitleText>
                        <SubTitleText>Choose a category that describes you most accurately</SubTitleText>
                    </TopMasterRowColumn>
                </TopMasterRow>
                <InnerInnerInnerContainer>
                    <RandomImage src={AffiliateRandom} />
                    <CasualColumn>
                        <Casual>
                            
                            <TitleText>Casual</TitleText>
                            <TextWrapper>
                            <SubTitleTextA>Users in this category are regular participants who consistently
                                use the app for mining but have limited involvement in external promotion
                                or community engagement. However, they regularly like and share social media posts
                                and occasionally leave positive comments.
                                
                            </SubTitleTextA>
                            </TextWrapper>
                            <Button>
                                <ButtonText>Coming Soon</ButtonText>
                            </Button>
                            <Message>*25% Mining Bonus</Message>
                        </Casual>
                        
                    </CasualColumn>
                    <CasualColumn>
                        <Casual>
                            
                            <ATitleText>Supporter</ATitleText>
                            <TextWrapper>
                            <SubTitleTextA>Supporters go beyond casual use. They actively engage in the Hydrix
                                community by sharing app updates, inviting friends to join, and contributing to
                                discussions. They promote the app regularly on their social media,
                                encouraging others to try it.
                            </SubTitleTextA>
                            </TextWrapper>
                            <Button>
                                <ButtonText>ComingSoon</ButtonText>
                            </Button>                        
                            <Message>*50% Mining Bonus</Message>
                        </Casual>
                        
                    </CasualColumn>
                    <CasualColumn>
                        <Casual>
                            
                            <BTitleText>Influencer</BTitleText>
                            <TextWrapper>
                            <SubTitleTextA>Influencers are key advocates of Hydrix, consistently promoting the app
                                across social media, blogs, or other platforms. They generate significant
                                engagement through content creation, such as reviews, guides, or sharing their
                                experience, helping to attract and retain a wider user base.
                            </SubTitleTextA>
                            </TextWrapper>
                            <Button>
                                <ButtonText>Coming Soon</ButtonText>
                            </Button>
                            <Message>*100% Mining Bonus</Message>
                        </Casual>
                        
                    </CasualColumn>
                </InnerInnerInnerContainer>
            </InnerContainer>
            
            
            <NewFooter />
        </Container>
        
    );
};

export default Affiliate;

import styled from "styled-components";
import ScreenShot from '../../assets/Screenshot.png';
import ScreenShot2 from '../../assets/ScreenShot2.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";


const Container = styled.div`
    width: 100%;
    height: auto; 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    `
const Wrapper = styled.div`
    width: 95%;
    max-width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    background-color: #00011c;
    margin-bottom: 20px;
    border-radius: 4px;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    `
const TitleText = styled.div`
    color: white;
    font-size: 32px;
    font-family: Anek Devanagari;
    font-weight: 700;
    margin-bottom: 15px;
    margin: 0 auto;
    width: 80%;
    margin-top: 15px;
    padding: 15px;
    line-height: .925;
    `
const HeaderRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    `
const TitleContainer = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    `
const Image = styled.img`
    width: 40px;
    height: 30px;
    margin-right: 5px;
    `
const ImageA = styled.img`
    width: 30px;
    height: 30px;
    
    `
const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: #c1cade;
    font-family: DM Sans;
    text-align: left;
    margin: 0 auto;
    width: 80%;
    margin-bottom: 40px;
   
    `
const ButtonA = styled.button`
    border-radius: 4px;
    border: 2px solid #FF4DCF;
    height: 60px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #FF4DCF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; 
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: black;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 16px
    `
const ScreenShotImg = styled.img`
    width: 70%;
    height: 300px;
    `
const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    `
const ImageRowA = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    `
const ImagePhone = styled.img`
    width: 70%;
    height: auto;
    `
const HomePageSixth = () => {
    return (
        <Container>
            <Wrapper>
                <TitleText>About</TitleText>
                <Subtitle>Hydrix makes crypto mining effortless and rewarding, 
                    bringing cryptocurrency within everyone’s reach. Whether you're
                    new to crypto or an expert, Hydrix lets you earn rewards seamlessly 
                    from your smartphone—no expensive hardware or complicated setups required.
                    With a simple, intuitive interface, you can start mining with a tap. Our
                    tiered reward system offers bonuses of up to 100%, and you can boost 
                    your earnings further by building a team and growing the Hydrix network.
                    Hydrix prioritizes security, transparency, and sustainability. Your data 
                    is safe, your progress is clear, and you can mine without the 
                    energy-intensive costs of traditional methods.
                    Join a community shaping the future of digital assets. 
                    Download Hydrix today and start building your crypto portfolio!
                </Subtitle>              
            </Wrapper>
        </Container>
    )
};

export default HomePageSixth;

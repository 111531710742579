import styled from 'styled-components';
import { useState } from 'react';
import Logo from '../assets/Logo.png';
import { Icon } from '@iconify/react';

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Manrope;
  height: 100px;
  z-index: 999;
  @media screen and (max-width: 900px) {
    height: 60px;
    `
const TitleContainer = styled.div`
    width: 20%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 50px;
    z-index: 5;
    @media screen and (max-width: 900px) {
        margin-left: 20px;;
      }
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: pink;
    padding: 10px;
    width: 175px;
    margin-right: 50px;
    border: 2px solid pink;
    cursor: pointer;

    `
const ButtonText = styled.div`
    color: #001B39;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const Subtitle = styled.div`
    font-size: 24px;
    font-weight: 600;
    color: white;
    font-family: Manrope;
    @media screen and (max-width: 900px) {
        display: none;
      }
    `
const Image = styled.img`
    width: 100px;
    height: 75px;
    @media screen and (max-width: 900px) {
        width: 40px;
        height: 30px;
        margin-right: 20px;
      }
    `

const Header = () => {
    
    const [show, setShow] = useState(false);
    const toggleDropdown = () => setShow(!show);

    const [show1, setShow1] = useState(false);
    const toggleDropdown1 = () => setShow1(!show1);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModal2Open, setIsModal2Open] = useState(false);
    const [isModal3Open, setIsModal3Open] = useState(false); 




    return (
        <Container>
            <TitleContainer>
                <Image src={Logo} />
                <Subtitle>Hydrix</Subtitle>
            </TitleContainer>
            <a href="https://play.google.com/store/apps/details?id=com.HydrixApp" target="_blank" rel="noopener noreferrer">
                <ButtonA>
                    <ButtonText>Download</ButtonText>
                </ButtonA>
            </a>

            
            
            
        </Container>

    );
};

export default Header;
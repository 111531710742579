import styled, { keyframes } from "styled-components";
import Logo from '../assets/Logo.png';
import MenuRight from '../assets/menuRight.png';
import HomePageSecond from "../components/HomePage/HomePageSecond";
import HomePageThird from '../components/HomePage/HomePageThird';
import HomePageFourth from "../components/HomePage/HomePageFourth";
import HomePageFifth from "../components/HomePage/HomePageFifth";
import HomePageSixth from '../components/HomePage/HomePageSixth';
import { useState } from "react";
import TelegramOverlay from '../assets/telegramOverlay.png';
import FacebookOverlay from '../assets/facebookOverlay.png';
import RedditOverlay from '../assets/redditOverlay.png';
import TwitterOverlay from '../assets/twitterOverlay.png';
import TikTokOverlay from '../assets/tiktokOverlay.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import FrontBackground from '../assets/FrontBackground.jpg';


const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`
const MasterContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    `
const Container = styled.div`
    width: 100%;
    height: auto;
    background-color: #00011c; //#00011c;
    clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    `
const Wrapper = styled.div`
    width: 95%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 60px;
    `
const TitleText = styled.div`
    color: white;
    font-size: 50px;
    font-family: Anek Devanagari;
    font-weight: 700;
    margin-bottom: 15px;
    line-height: .925;
    z-index: 999;
    span {
    color: #45D9F4;}
    `
const HeaderRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    `
const TitleContainer = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    `
const Image = styled.img`
    width: 40px;
    height: 30px;
    margin-right: 5px;
    `
const ImageA = styled.img`
    width: 30px;
    height: 30px;
    `
const ImageB = styled.img`
    width: 20px;
    height: 20px;
    margin-left: 7.5px;
    margin-right: 7.5px;
    `
const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: #c1cade;
    font-family: DM Sans;
    `
const SubtitleB = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: white;
    font-family: Work Sans;
    `
const StyledLink = styled(Link)`
    text-decoration: none; 
    color: inherit;        
    display: flex;
    width: 80%;  
  `;
const ButtonA = styled.button`
    border-radius: 4px;
    border: 2px solid pink;
    height: 100%;
    width: 100%;
    background-color: pink;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; 
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: black;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 16px
    `
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom, rgba(26, 1, 53, 0.85), rgba(26, 1, 53, 0.9), rgba(26, 1, 53, 1), rgba(26, 1, 53, 1));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 900;
    `
const SocialRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    opacity: 0;
    animation: ${slideInFromLeft} 0.5s ease 0.5s forwards;
    `
const MenuRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-left: 60px;
    margin-bottom: 20px;
    opacity: 0;
    animation: ${slideInFromLeft} 0.7s ease 0.7s forwards;
    `
const A = styled.a`
    border-radius: 4px;
    border: 2px solid pink;
    height: 60px;
    width: 80%;
    margin-top: 20px;
    background-color: pink;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    :& hover: {
    background-color: #FF4DCF; }
    `
const InfoDivA = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 200px;
    background: url(${FrontBackground});
    background-position: center;
    background-size: cover;
    z-index: 99;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 100px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 20px;
    `
const HomePage = () => {

    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    return (
    <MasterContainer>
        <Container>
            <HeaderRow>
                <TitleContainer>
                    <Image src={Logo} />
                    <SubtitleB>Hydrix</SubtitleB>
                </TitleContainer>
                <ImageA 
                    src={MenuRight}
                    onClick={() => setIsOverlayOpen(true)}
                 /> 
            </HeaderRow>
            <Wrapper>
                <InfoDivA />
                <TitleText>Mine <span>Hydrix</span> on your <span>Smartphone</span></TitleText>
                <Subtitle>Download the app today and join our growing community</Subtitle>
                <A href="https://play.google.com/store/apps/details?id=com.HydrixApp" target="_blank" rel="noopener noreferrer">
                    <ButtonA>
                        <ButtonText>Download Hydrix</ButtonText>
                        <FontAwesomeIcon icon={faChevronRight} color="black" size="2" />
                    </ButtonA>
                </A>
            </Wrapper>
        </Container>
            <HomePageSixth />
            <HomePageSecond />
            <HomePageFourth />
            <HomePageThird />
            <HomePageFifth />
{isOverlayOpen && (
        <Overlay
            onClick={() => setIsOverlayOpen(false)}
        >
            <Wrapper>
                <StyledLink to="/mobileapi">
                    <MenuRow style={{ marginTop: '30%' }}>
                            <SubtitleB>Hydrix Api</SubtitleB>
                            <FontAwesomeIcon icon={faChevronRight} color="white" size="xs" />
                        
                    </MenuRow>
                </StyledLink>
                <StyledLink to="/mobileaffiliate">
                    <MenuRow>
                        <SubtitleB>Affiliate Programme</SubtitleB>
                        <FontAwesomeIcon icon={faChevronRight} color="white" size="xs" />
                    </MenuRow>
                </StyledLink>
                
                <SocialRow>
                <a href="https://t.me/HydrixApp" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <ImageB src={TelegramOverlay} />
                </a>
                    <ImageB src={FacebookOverlay} />
                    <ImageB src={RedditOverlay} />
                    <a href="https://www.tiktok.com/@hydrix512" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <ImageB src={TikTokOverlay} />
                    </a>
                    <a href="https://www.twitter.com/hydrixapp" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <ImageB src={TwitterOverlay} />
                    </a>
                </SocialRow>
                
            </Wrapper>

            </Overlay>
)}
    </MasterContainer>
    )
};

export default HomePage;

import styled from "styled-components";
import Wallet from '../../assets/wallet.png';
import Mine from '../../assets/mine.png';
import Water from '../../assets/water.png';
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import HydrixWalletGraphic from '../../assets/hydrixWallet.png';
import AfterMiner from '../../assets/afterMiner.png';
import LPRewards from '../../assets/lpRewards.png';

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 120vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00011c;
    `
const InnerContainer = styled.div`
    width: 90%;
    max-width: 90%;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    `
const RadialGradientC = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 300px;
    zIndex: 19;
    `
const RadialGradientD = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: 0px;
    left: 300px;
    zIndex: 19;
    `
const RadialGradientE = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: 0px;
    left: 300px;
    zIndex: 19;
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    `
const TitleText = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 62px;
    span {
    color: #45D9F4; }
    `
const TitleTextA = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 700;
    font-size: 46px;
    span {
    color: #45D9F4; }
    `
const SuperTitleText = styled.div`
    color: #c1cade;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 22px;
    `
const IconContainer = styled.div`
    height: 250px;
    width: 250px;
    align-items: center;
    justify-content: center;
    display: flex;
    `
const SubTitleText = styled.div`
    color: #c1cade;
    font-family: DM Sans;
    font-weight: 500;
    font-size: 16px;
    
    `
const HydrixWallet = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 32%;
   
    `
const HydrixWalletInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 70%;
    width: 90%;
    @media screen and (max-width: 900px) {
    flex-direction: column;}
    `
const HydrixWalletColumn = styled.div`
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: flex-start;
    justify-content: flex-start;
    `
const HydrixWalletColumnMLeft = styled.div`
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 30px;
    @media screen and (max-width: 900px) {
    margin-left: 0;}
    `
const FutureRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 225px;
    margin-top: 20px;
    margin-bottom: 50px;
    @media screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;}
    `
const FutureRowLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 49%;
    height: 100%;
    border: 0.5px solid #333333;
    border-radius: 4px;
    @media screen and (max-width: 900px) {
    height: 48%;
    width: 90vw;
    margin-bottom: 20px;
    flex-direction: column;
    padding-bottom: 20px;}
    `
const FutureRowRight = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    align-items: center;
    justify-content: flex-start;
    width: 49%;
    height: 100%;
    border: 0.5px solid #333333;
    @media screen and (max-width: 900px) {
    height: 48%;
    width: 90vw;
    margin-bottom: 20px;
    flex-direction: column;
    padding-bottom: 20px;}
    `
const Image = styled.img`
    width: 200px;
    height: auto;
    
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: pink;
    border: 2px solid pink;
    padding: 15px;
    width: 200px;
    &: hover {
    background-color: #FF4DCF;
    }
    `
const ButtonC = styled.button`
    border-radius: 4px;
    background-color: #FF4DCF;
    border 2px solid #FF4DCF;
    padding: 15px;
    width: 200px;
    margin-top: 40px;
    
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: #001B39;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `



const SixthContainer = ({ RadialGradient, RadialGradientB }) => {
    return (
        <Container>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <SuperTitleText>NEXT</SuperTitleText>
                        <TitleText>The Future</TitleText>
                    </TopMasterRowColumn>
                </TopMasterRow>
                    
                        <ButtonA>
                            <ButtonText>Coming Soon</ButtonText>
                        </ButtonA>
                    
            <InnerContainer>
                
                <HydrixWallet>
                    <HydrixWalletInner>
                        <IconContainer>
                            <Image src={HydrixWalletGraphic} />
                        </IconContainer>
                            <TitleTextA>Hydrix <span>Wallet</span></TitleTextA>
                            <SubTitleText>Join us on our journey as we develop Hydrix Wallet. A secure, modern way to
                                 buy, sell, and hold your Hydrix while exploring the blockchain and the world of Web3.
                                
                            </SubTitleText>
                        
                    </HydrixWalletInner>
                    
                </HydrixWallet>
                <HydrixWallet>
                    <HydrixWalletInner>
                        <IconContainer>
                            <Image src={AfterMiner} />
                        </IconContainer>
                            <TitleTextA>After <span>Miner</span></TitleTextA>
                            <SubTitleText>Hydrix users can lock their mining balance (or buy) an
                                afterminer. This replaces staking, capping Hydrix inflation at 10%. Once the mining pool
                                is gone, its gone forever!
                                
                            </SubTitleText>
                            </HydrixWalletInner>
                </HydrixWallet>
                <HydrixWallet>
                    <HydrixWalletInner>
                        <IconContainer>
                            <Image src={LPRewards} />
                        </IconContainer>
                    <TitleTextA><span>LP</span> Rewards</TitleTextA>
                            <SubTitleText>Hydrix will depoly our own smart contract (LP farm)
                                 to reward those users that provide liquidity for Hydrix after 
                                 we launch. Check back here for more details</SubTitleText>
                    </HydrixWalletInner>
                </HydrixWallet>      
                            
                       
                    
                            
                       
                
            </InnerContainer>
        </Container>

    )
};

export default SixthContainer;
import styled from "styled-components";
import AppStore from '../../assets/AppStore.png';
import GooglePlay from '../../assets/googlePlay.png';

const Container = styled.div`
    width: 100%;
    height: auto; 
    display: flex;
    flex-direction: column;
    background-color: #00011c;
    align-items: center;
    `
const Wrapper = styled.div`
    width: 90%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 60px;
    `
const TitleText = styled.div`
    color: white;
    font-size: 42px;
    font-family: Anek Devanagari;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
    `
const HeaderRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    `
const TitleContainer = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    `
const Image = styled.img`
    width: 40px;
    height: 30px;
    margin-right: 5px;
    `
const ImageA = styled.img`
    width: 30px;
    height: 30px;
    
    `
const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: #c1cade;
    font-family: DM Sans;
    `
const SubtitleA = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: white;
    font-family: DM Sans;
    `
const ButtonA = styled.button`
    border-radius: 4px;
    border: 2px solid #00FAD9;
    height: 60px;
    width: 80%;
    margin-top: 30px;
    background-color: #00FAD9; 
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: #00011c;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const ScreenShotImg = styled.img`
    width: 70%;
    height: 300px;
    `
const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 60px;
    
    `
const Download =styled.img`
    width: 100%;
    height: auto;
    `
const DownloadA =styled.img`
    width: 70%;
    height: auto;
    `
const A =styled.a`
    width: 70%;
    height: auto;
    `
const HomePageThird = () => {
    return (
        <Container>
            <Wrapper>
                <TitleText>Download Now!</TitleText>
                <Subtitle>Simply download the app, create an account, add your referrer and
                    start mining, it's that simple!
                </Subtitle>
                <ImageRow>
                    <DownloadA src={AppStore} />
                </ImageRow>
                <ImageRow style={{ marginTop: '15px' }}>
                    <SubtitleA>Or</SubtitleA>
                </ImageRow>
                <ImageRow style={{ marginTop: '15px' }}>
                <A href="https://play.google.com/store/apps/details?id=com.HydrixApp" target="_blank" rel="noopener noreferrer">

                    <Download src={GooglePlay} />
                </A>
                </ImageRow>
              
            </Wrapper>
            
        </Container>
    )
};

export default HomePageThird;

import styled from "styled-components";
import apiIcon from '../../assets/apiIcon.png';
import Document from '../../assets/document.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { Link } from 'react-router-dom';
import AffiliateScreenshot from '../../assets/affiliateScreenshot.png';
import AffiliateGraphic from '../../assets/AffiliateGraphic.png';
import Logo from '../../assets/Logo.png';


const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: auto;
    padding-bottom: 50px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white; 
    
    `
const InnerContainer = styled.div`
    width: 80%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    height: 700px;
    background-color: #00011c;
    border-top-left-radius: 200px;
    border-top-right-radius: 4px;
    borderbottom-left-radius: 4px;
    border-bottom-right-radius: 200px;
    `
const InnerContainerColumn = styled.div`
    height: 100%;
    display: flex;
    width: 49%;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
    width: 90%;
    height: auto; }
    `
const TopMasterRow = styled.div`
    width: 70%;
    max-width: 70%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    @media screen and (max-width: 900px) {
    margin-bottom: 15px;
    }
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    `
const ApiComponent = styled.div`
    width: 100%;
    height: 48%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0.5px solid #333333;
    `
const AffiliateComponent = styled.div`
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 4px;
    margin-left: 100px;
    `
const ApiComponentRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    }
    `
const ApiComponentColumn = styled.div`
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 20px;
    @media screen and (max-width: 900px) {
    width: 90%;
    justify-content: center;
    align-items: center;
    }
    `
const AffiliateComponentColumn = styled.div`
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    @media screen and (max-width: 900px) {
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    text-align: center;
    margin-left: 0;
    }
    `
const DownloadComponentColumn = styled.div`
    display: flex;
    flex-direction: column;
    `
const IconContainerA = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    `
const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    border: 1px solid #262626;
    border-radius: 24px;
    background: linear-gradient(to right, rgba(15, 15, 15, 1), rgba(15, 15, 15, 1), rgba(15, 15, 15, 0.9), rgba(15, 15, 15, 0.8), rgba(15, 15, 15, 0.3));
    `
const TitleText = styled.div`
    color: white;
    font-family: Anek Devanagari;
    font-weight: 900;
    font-size: 42px;
    span {
    color: #45D9F4 }
    `
const TitleTextA = styled.div`
    color: white; //#001226;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 32px;
    @media screen and (max-width: 900px) {
    font-size: 18px;
    width: 100%;
    text-align: center;}
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    text-align: center;}
    `
const AffSubTitleText = styled.div`
    color: #c1cade;
    font-family: DM Sans;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    width: 70%;
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: pink;
    border: 2px solid pink;
    padding: 15px;
    width: 200px;
    margin-top: 20px;
    &:hover {
    background-color: #FF4DCF ;
    }
    `
const ButtonText = styled.div`
    color: #001B39;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const IconContainer = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background-color: rgba(0, 250, 217, 0.3);
    align-items: center;
    margin-right: 20px;
    justify-content: center;
    display: flex;
    @media screen and (max-width: 900px) {
    margin-bottom: 20px;}
`
const Image = styled.img`
    width: 300px;
    height: auto;
    position: relative;
    z-index: 999;
    `
const ImageB = styled.img`
    position: absolute;
    z-index: 9999;
    width: 300px;
    height: auto;
    bottom: 10%;
    right: 30%;
    `
const BackBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 250px;
    height: 400px;
    background-color: #45D9F4;  
    z-index: 98;
    border-radius: 4px;
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
    position: absolute;
    bottom: 8%;
    right: 27.5%;
    `


const FourthContainer = ({ RadialGradientA, RadialGradientB }) => {
    return (
        <Container>
            
            <InnerContainer>
                <AffiliateComponent>
                    
                        <TitleText><span>Affiliate</span> Programme</TitleText>
                        <AffSubTitleText>To help expand our community, Hydrix aims to fully support users
                                that put Hydrix at the centre of everything they do. You dont need to be 
                                an influencer to join the affiliate programme, just someone willing to promote 
                                and educate others about Hydrix.
                        </AffSubTitleText>
                        <Link to="/affiliate">
                            <ButtonA>
                                <ButtonText>Interested?</ButtonText>
                            </ButtonA>
                        </Link>
                        <AffSubTitleText>In return Hydrix has a number of ways to reward our affiliates, from our community
                            bonus scheme to a limited 'after-miner'. Read about it here.
                        </AffSubTitleText>
                    
                </AffiliateComponent>
                <AffiliateComponent>
                    <BackBox />
                    <Image src={AffiliateScreenshot} />
                </AffiliateComponent>
            </InnerContainer>
        </Container>

    )
};

export default FourthContainer;